import React from "react";
import phoneImage from "../assets/phone-mockup.png"; // Ensure the image path is correct

const PhoneMockup: React.FC = () => {
  return (
    <div className="mt-10">
      <img
        src={phoneImage}
        alt="Phone Mockup"
        className="w-70 md:w-80 mx-auto"
      />
    </div>
  );
};

export default PhoneMockup;
