import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 text-white mt-10 py-8">
      <div className="container mx-auto px-4 text-center">
        <p className="mb-4">&copy; 2024 Braintain. All rights reserved.</p>
        <div className="space-x-4">
          <a
            href="https://portal.termshub.io/braintain.app/privacy_policy"
            className="hover:underline"
          >
            Privacy Policy
          </a>
          <a href="mailto:braintain.app@gmail.com" className="hover:underline">
            Contact Us
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
