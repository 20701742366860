import React from "react";
import AppStoreButtons from "./AppStoreButtons";
import PhoneMockup from "./PhoneMockup";
import braintainLogo from "../assets/braintain_logo.png"; // Ensure the image path is correct
import Footer from "./Footer";

const HeroSection: React.FC = () => {
  return (
    <div className="flex flex-wrap justify-evenly h-screen bg-gradient-to-r from-primary to-blue-500 text-white text-center px-4 pt-20">
      <div className="flex flex-col">
        <div className="mt-10 mb-10">
          <img
            src={braintainLogo}
            alt="logo"
            className="w-40 sm:w-50 mx-auto"
          />
        </div>
        <h1 className="text-3xl sm:text-5xl font-bold mb-4">
          AI-Powered Vocabulary Boost
        </h1>
        <p className="text-lg md:text-xl mb-8 max-w-prose">
          This app is designed to help you effortlessly remember and expand your
          vocabulary in any language. With a variety of engaging exercises,
          personalized challenges, and innovative features, Braintain makes
          learning new words a fun and interactive experience.
        </p>
        <AppStoreButtons />
      </div>
      <div className="flex flex-col">
        <PhoneMockup />
      </div>
      <div className="flex flex-col w-full">
        <Footer />
      </div>
    </div>
  );
};

export default HeroSection;
