import React from "react";
import appStoreImage from "../assets/app_store_badge.png";
import playStoreImage from "../assets/google_play_button.png";

const AppStoreButtons: React.FC = () => {
  return (
    <div className="mt-8 flex space-x-4 justify-center">
      <a href="https://apps.apple.com/us/app/braintain/id1513710010">
        <img src={appStoreImage} alt="Appstore" className="w-30 md:w-40" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.morant.braintain">
        <img src={playStoreImage} alt="PlayStore" className="w-30 md:w-40" />
      </a>
    </div>
  );
};

export default AppStoreButtons;
