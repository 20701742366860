import React from "react";
import HeroSection from "./components/HeroSection";

const App: React.FC = () => {
  return (
    <div>
      <main className="pt-16 flex flex-col flex-wrap">
        <HeroSection />
      </main>
    </div>
  );
};

export default App;
